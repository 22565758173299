import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from '../components/Layout'
import {
  BannerDefault,
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
} from '../components/Elements'
import ContactForm from '../components/ContactForm/ContactForm'

// CSS
import '../styles/PrivacyDeclaration.scss'

// Images
import arrow from '../img/arrow-menu.svg'

function PrivacyDeclaration({ data }) {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="privacy-page">
        <BannerDefault>
          <div className="row">
            <Img
              className="position-absolute banner-image"
              fluid={
                page.acf.header.image.localFile
                  .childImageSharp.fluid
              }
            />
            <div className="col-12 col-lg-5 pb-4">
              <TitleDefault>
                {page.acf.header.title}
              </TitleDefault>
            </div>
          </div>
        </BannerDefault>
        <section className="content-row">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-9">
                <TitleAlt>
                  <img src={arrow} alt="" width="18" />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.title,
                    }}
                  />
                </TitleAlt>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.content,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PrivacyDeclaration

export const KennisQuery = graphql`
  query PrivacyQuery {
    wordpressPage(wordpress_id: { eq: 152 }) {
      title
      content
      acf {
        header {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
